import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Containers
import { Banner } from "../containers/common"
import { Form } from "../containers/createTribute"

// redux
import { useSelector, useDispatch } from "react-redux"
import TributeFormMiddlewares from "../store/middlewares/TributeFormMiddlewares"

const CreateTributePage = ({ data }) => {
  const dispatch = useDispatch()
  const { tribute } = useSelector(state => state)
  const [retribute, setRetribute] = useState(false)
  const [reTributeData, setReTributeData] = useState({})
  const {
    ogtitle,
    ogdescription,
    title,
    formTitle,
    formDetails,
    image,
    featureMomDetails,
  } = data.createTribute

  const formSectionData = { formTitle, formDetails, image, featureMomDetails }

  useEffect(() => {
    if (tribute.allMoms.length < 1) {
      dispatch(TributeFormMiddlewares.fetchMoms())
    }
  }, [tribute.allMoms])

  return (
    <Layout>
      <SEO title={ogtitle} description={ogdescription} />
      <Banner title={title} />
      <Form
        data={formSectionData}
        reTribute={retribute}
        toggleTribute={setRetribute}
        reTributeData={reTributeData}
        setReTributeData={setReTributeData}
      />
    </Layout>
  )
}

export const query = graphql`
  query CreateTributePageQuery {
    createTribute: contentfulCreateTributePage {
      ogtitle
      ogdescription
      title
      formTitle
      formDetails
      image {
        title
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      featureMomDetails {
        raw
      }
    }
  }
`

export default CreateTributePage
