import React from "react"

// Styles
import styles from "./index.module.scss"

const ProgressBar = ({ completed }) => {
  return (
    <div
      style={{ width: `${completed}%` }}
      className={styles.progressBar}
    ></div>
  )
}

export default ProgressBar
