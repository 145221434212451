import TributeFormActions from "../actions/TributeFormActions"
import Axios from "../../config/config"
import path from "../../config/path"

export default class TributeFormMiddlewares {
  static imageUploadToCloudinary(image) {
    return async (dispatch, getState) => {
      dispatch(TributeFormActions.imageUploadToCloudinary())
      try {
        const response = await Axios.post(path.IMAGE_UPLOAD_TO_CLOUDINARY, {
          image,
        })
        dispatch(
          TributeFormActions.imageUploadToCloudinarySuccess({
            successMessage: "Image upload to cloudinary Success!",
          })
        )
      } catch (error) {
        dispatch(
          TributeFormActions.imageUploadToCloudinaryFailed({
            errorMessage: "image failed to upload to cloudinary",
          })
        )
      }
    }
  }
  static imageUploadToContentful(image) {
    return async (dispatch, getState) => {
      dispatch(TributeFormActions.imageUploadToContentful())
      try {
        const response = await Axios.post(path.IMAGE_UPLOAD_TO_CONTENTFUL, {
          image,
        })
        dispatch(
          TributeFormActions.imageUploadToContentfulSuccess({
            successMessage: "Image upload to contentful Success!",
          })
        )
      } catch (error) {
        dispatch(
          TributeFormActions.imageUploadToContentfulFailed({
            errorMessage: "image failed to upload to Contentful",
          })
        )
      }
    }
  }
  static tributeFormSubmission(momFields, tributeFields) {
    return async (dispatch, getState) => {
      dispatch(TributeFormActions.tributeFormSubmission())
      try {
        const response = await Axios.post(path.TRIBUTE_FORM_SUBMISSION, {
          momFields,
          tributeFields,
        })
        dispatch(
          TributeFormActions.tributeFormSubmissionSuccess({
            successMessage: "Form Submitted Successfully!",
          })
        )
      } catch (error) {
        dispatch(
          TributeFormActions.tributeFormSubmissionFailed({
            errorMessage: "Form Failed to submit",
          })
        )
      }
    }
  }
  static fetchMoms() {
    return async (dispatch, getState) => {
      dispatch(TributeFormActions.getAllMoms())
      try {
        const response = await Axios.get(path.GET_ALL_MOMS)
        return dispatch(
          TributeFormActions.getAllMomsSuccess({
            allMoms: {
              totalMoms: response.data.totalMoms,
              featuredDates: response.data.featuredDates,
            },
            successMessage: "Moms fetched Successfully!",
          })
        )
      } catch (error) {
        return dispatch(
          TributeFormActions.getAllMomsFailed({ errorMessage: error.message })
        )
      }
    }
  }
}
