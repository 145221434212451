import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useDispatch } from "react-redux"
import TributeFormActions from "../../../store/actions/TributeFormActions"

// Components
import { CloseBtn, VLine } from "../../../components/icons"

// Stylesheet
import styles from "./index.module.scss"

const TributesFound = ({ moms, toggle, setData }) => {
  const dispatch = useDispatch()

  return (
    <div className={styles.tributesFound}>
      {moms &&
        moms.length > 0 &&
        moms.map(
          (
            {
              totalTributes,
              mom: {
                sys: { id },
                fields: {
                  birthCity,
                  birthState,
                  firstName,
                  lastName,
                  image: {
                    fields: {
                      file: { url, fileName },
                    },
                  },
                },
              },
            },
            index
          ) => {
            const name = `${firstName} ${lastName}`
            return (
              <div key={index} className={styles.tribute}>
                <div className="w-full md:w-1/2 flex justify-start items-center">
                  <div
                    style={{ backgroundImage: `url(${url})` }}
                    className={styles.imgContainer}
                  />
                  <div>
                    <h4 className={styles.name}>{name}</h4>
                    <p
                      className={styles.location}
                    >{`${birthCity}, ${birthState}`}</p>
                  </div>
                </div>

                <div className="w-full md:w-1/2 flex justify-between items-center">
                  <div className="flex justify-between items-center">
                    <VLine
                      className={`${styles.seperator} hidden md:block md:mr-8`}
                    />
                    <div className="flex md:flex-col items-center">
                      <h5 className="mr-4 md:mr-0">Tributes</h5>
                      <span className={styles.primary}>{totalTributes}</span>
                    </div>
                  </div>
                  <Link
                    to="/create-tribute#retribute"
                    onClick={() => {
                      toggle(true)
                      setData({
                        id: id,
                        img: url,
                        name: name,
                        location: `${birthCity}, ${birthState}`,
                      })
                      dispatch(TributeFormActions.toggleReTribute())
                    }}
                    type="button"
                    className={`${styles.tributeBtn} btn twine`}
                  >
                    Give a Tribute
                  </Link>
                </div>
              </div>
            )
          }
        )}
    </div>
  )
}

const SimilarTributesSection = ({ data, toggle, setReTributeData }) => {
  const [proceed, setProceed] = useState(false)
  const [show, setShow] = useState(true)

  return (
    <div
      className={`${styles.similarTributesSection} ${styles.proceed} ${
        !show ? styles.hidden : ""
      }`}
    >
      <CloseBtn className={styles.closeBtn} onClick={() => setShow(false)} />
      <p className={styles.details}>
        Hey! We're found{" "}
        <span className={styles.primary}>{data.length} moms</span> with the same
        name and birth date.
        <br />
        <span>You can give a tribute to these moms.</span>
      </p>
      {!proceed && (
        <button
          type="button"
          className={`${styles.btn} btn twine text-white`}
          onClick={() => setProceed(true)}
        >
          Wow! Let's do it
        </button>
      )}
      {proceed && (
        <TributesFound moms={data} toggle={toggle} setData={setReTributeData} />
      )}
    </div>
  )
}

export default SimilarTributesSection
