import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
import DatePicker from "react-datepicker"
import { EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import { convertToRaw } from "draft-js"
import { loadStripe } from "@stripe/stripe-js"
import Swal from "sweetalert2"

// Components
import {
  Input,
  CheckBox,
  Textarea,
  Dropdown,
  FileUpload,
  CustomDatePicker,
} from "../../../components/inputFields"
import { HeartIcon } from "../../../components/icons"
import RichTextRenderer from "../../../components/richTextRenderer"
import ProgressBar from "../../../components/progressBar"
import SimilarTributesSection from "../similarTributesFound"
import { useDispatch, useSelector } from "react-redux"
import TributeFormMiddlewares from "../../../store/middlewares/TributeFormMiddlewares"
import {
  allUSState,
  allUSCities,
} from "../../../site-data/create-tribute/tribute-form"

// Stylesheet
import "react-datepicker/dist/react-datepicker.css"
import "./datepicker.css"
import styles from "./index.module.scss"
import TributeFormActions from "../../../store/actions/TributeFormActions"

// Images
import loader from "../../../images/loading.svg"

// About create tribute
const AboutForm = ({
  title,
  details,
  image,
  reTribute,
  data: { name, img, location, id },
}) => (
  <div className={`${styles.aboutForm} md:flex items-center`}>
    {reTribute ? (
      <div id="retribute" className="flex flex-col items-center">
        <h3>Give a tribute</h3>
        <p className={styles.tributeDesc}>
          Give us the funny, inspirational, heart-warming, encouraging, and the
          “here’s my advice” tribute to your Mom or Mom Figure.
        </p>
        <div
          style={{ backgroundImage: `url(${img})` }}
          className={styles.detailsContainer}
        >
          <HeartIcon className={`${styles.heartIcon} ${styles.right}`} />
          <HeartIcon className={`${styles.heartIcon} ${styles.left}`} />

          <div className={styles.tributeDetails}>
            <h4>{name}</h4>
            <p>{location}</p>
          </div>
        </div>
      </div>
    ) : (
      <>
        <div>
          <h4>{title}</h4>
          <p className="w-full md:w-11/12 small">{details}</p>
        </div>
        <Img
          fluid={image.fluid}
          alt={image.title}
          className="md:w-3/4 mx-auto"
        />
      </>
    )}
  </div>
)

const TributeSubmissionSchema = Yup.object().shape({
  momFirstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  momLastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  momMaidenName: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  featured: Yup.boolean(),
  promoCode: Yup.string(),
})

const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`)

// Send confirmation email
const sendConfirmationEmail = async email => {
  if (email) {
    const response = await axios.post(
      "/.netlify/functions/confirmation-email",
      {
        email: email,
      }
    )
  }
}

// Tribute creation form
const SubmissionForm = ({
  featureDetails,
  setReTributeData,
  toggleTribute,
  reTribute,
  momId,
  setProgress,
}) => {
  const { allMoms, ...state } = useSelector(state => state.tribute)
  const dispatch = useDispatch()
  const [birthStateCategory, setBirthStateCategory] = useState("Select State")
  const [birthCityCategory, setBirthCityCategory] = useState("Select City")
  const [currentStateCategory, setCurrentStateCategory] = useState(
    "Select State"
  )
  const [featuredDate, setFeaturedDate] = useState(null)
  const [currentCityCategory, setCurrentCityCategory] = useState("Select City")
  const [vitalStatus, setVitalStatus] = useState("Select Status")
  const [startDate, setStartDate] = useState(null)
  const [images, setImages] = useState([])
  const [videoUrls, setVideoUrls] = useState([])
  const [price, setPrice] = useState(process.env.GATSBY_STANDARD_TRIBUTE_PRICE)
  const [states, setStates] = useState(
    allUSState.records.map(({ fields }) => fields.stusab).sort()
  )
  const [cities, setCities] = useState([])
  const [currentCities, setCurrentCities] = useState([])
  const [tributeDescription, setTributeDescription] = useState(
    EditorState.createEmpty()
  )
  const [error, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [couponsList, setCouponsList] = useState([])
  const [activeCoupon, setActiveCoupon] = useState(null)
  const [featuredPrice, setFeaturedPrice] = useState(null)
  const standardTributePrice =
    Number(process.env.GATSBY_STANDARD_TRIBUTE_PRICE) || 1.99
  const featuredTributePrice =
    Number(process.env.GATSBY_FEATURED_TRIBUTE_PRICE) || 9.99

  const handleTributeDescription = description => {
    setTributeDescription(description)
  }

  const stripeFunction = async (price, discount) => {
    const session = await axios.post("/.netlify/functions/stripe-session", {
      price,
      quantity: 1,
      discount,
    })
    const sessionId = session.data.session.id
    const stripe = await stripePromise
    stripe.redirectToCheckout({ sessionId })
  }

  const formSubmit = async (values, onSubmitProps) => {
    if (
      values &&
      currentStateCategory !== "Select State" &&
      currentCityCategory !== "Select City" &&
      images.length >= 1
    ) {
      if (values.featured && !featuredDate) {
        setError(true)
      } else {
        if (reTribute) {
          formSubmissionHandler(values, onSubmitProps)
        } else if (
          birthStateCategory !== "Select State" &&
          birthCityCategory !== "Select City" &&
          startDate
        ) {
          formSubmissionHandler(values, onSubmitProps)
        }
      }
    } else {
      setError(true)
    }
  }

  const formSubmissionHandler = async (values, onSubmitProps) => {
    setLoading(true)
    const totalUploads = images.length + videoUrls.length
    const chunk = Math.ceil(100 / totalUploads) - 1

    const {
      momMaidenName,
      momLastName,
      momFirstName,
      featured,
      promoCode,
      name,
      email,
    } = values
    const rawContentState = convertToRaw(tributeDescription.getCurrentContent())

    if (featured && !featuredDate) return null

    let uploadedImagesID = []
    let uploadedVideosID = []
    // Uploading by looping over images
    for (let i in images) {
      // dispatch(TributeFormMiddlewares)
      const response = await axios.post(
        "https://tribute2moms.herokuapp.com/api/v1/tribute/tribute-images-upload-to-contentful",
        {
          image: images[i],
        }
      )
      uploadedImagesID.push(response.data.uploadedImage.sys.id)
      images[i].id = response.data.uploadedImage.sys.id
      setProgress(prev => prev + chunk)
    }
    for (let i in videoUrls) {
      // dispatch(TributeFormMiddlewares)
      const response = await axios.post(
        "https://tribute2moms.herokuapp.com/api/v1/tribute/tribute-video-upload-to-contentful",
        {
          video: videoUrls[i],
        }
      )
      uploadedVideosID.push(response.data.uploadedVideo.sys.id)
      videoUrls[i].id = response.data.uploadedVideo.sys.id
      setProgress(prev => prev + chunk)
    }

    // Tribute slider images
    // let tributeImages = [...images, ...videoUrls]

    const d = new Date(featuredDate)
    const date = d.getMonth() + 1
    let formatedDate = `${d.getFullYear()}-${date
      .toString()
      .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`

    const getCurrentCity = allUSCities.filter(
      entry => entry.city === currentCityCategory
    )

    // tribute submission request
    const tributeSubmissionResponse = await axios.post(
      "https://tribute2moms.herokuapp.com/api/v1/tribute/tribute-submission",
      {
        tributeFields: {
          featured,
          featuredDate: featured ? formatedDate : null,
          authorName: name.trim(),
          authorEmail: email,
          alive: vitalStatus.toLowerCase() == "alive" ? true : false,
          tributeDescription: draftToHtml(rawContentState),
          currentCity: currentCityCategory,
          currentState: currentStateCategory,
          images: images,
          videos: videoUrls,
          longitude: getCurrentCity[0].lng,
          latitude: getCurrentCity[0].lat,
        },
        momFields: {
          id: momId,
          isMomExisted: reTribute,
          momFirstName: momFirstName.trim(),
          momLastName: momLastName.trim(),
          momMaidenName: momMaidenName.trim(),
          imageId: images[0].id,
          birthDate: startDate,
          birthState: birthStateCategory,
          birthCity: birthCityCategory,
        },
      }
    )
    const { entryTribute, momEntry } = tributeSubmissionResponse.data

    if (price <= 0) {
      const response = await axios.post("/.netlify/functions/publish-tribute", {
        momID: momEntry ? momEntry.sys.id : null,
        tributeID: entryTribute.sys.id,
      })
      if (response.status === 200) {
        const email =
          response.data.publisedTributeEntry.fields.authorEmail["en-US"]
        sendConfirmationEmail(email)
        localStorage.removeItem("tributeID")
        localStorage.removeItem("momID")
        Swal.fire({
          icon: "success",
          title: "Successful!",
          text: response.data.message,
          showConfirmButton: false,
          timer: 3000,
        })
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        })
      }
    } else {
      if (typeof window !== "undefined") {
        localStorage.setItem("tributeID", entryTribute.sys.id)
        !reTribute && localStorage.setItem("momID", momEntry.sys.id)
      }
      const price = values.featured
        ? process.env.GATSBY_STRIPE_FEATURED_TRIBUTE_PRICE
        : process.env.GATSBY_STRIPE_STANDARD_TRIBUTE_PRICE
      const discountCoupon = activeCoupon ? activeCoupon.coupon.id : null

      await stripeFunction(price, discountCoupon)
    }
    setProgress(100)

    onSubmitProps.resetForm()
    setBirthStateCategory("Select State")
    setBirthCityCategory("Select City")
    setCurrentStateCategory("Select State")
    setCurrentCityCategory("Select City")
    setVitalStatus("Select Status")
    setStartDate(null)
    setFeaturedDate(null)
    setImages([])
    setVideoUrls([])
    setCities([])
    setCurrentCities([])
    setActiveCoupon(null)
    setError(false)
    setLoading(false)
  }

  // UseEffect to fetch all coupons on component mount
  useEffect(() => {
    fetchCoupons()
  }, [])

  // UseEffect to handle price on coupon/featured updates
  useEffect(() => {
    const currentPrice = featuredPrice
      ? featuredTributePrice + standardTributePrice
      : standardTributePrice
    if (activeCoupon) {
      if (activeCoupon?.coupon?.amount_off) {
        const amountOff =
          currentPrice - Number(activeCoupon?.coupon?.amount_off / 100)
        setPrice(amountOff)
      } else {
        const amountOff =
          currentPrice -
          currentPrice * (activeCoupon?.coupon?.percent_off / 100)
        setPrice(amountOff)
      }
    } else {
      featuredPrice
        ? setPrice(featuredTributePrice + standardTributePrice)
        : setPrice(standardTributePrice)
    }
  }, [activeCoupon])

  // Fetch all coupons API
  const fetchCoupons = async () => {
    const list = await axios.post("/.netlify/functions/get-promotional-codes")

    if (list.data.coupons) {
      const validCodes = list.data.coupons.data.filter(
        promo => promo.active && promo.coupon.valid
      )

      setCouponsList(validCodes)
    }
  }

  // Validate coupon if exists
  const handlePromotionalCode = async value => {
    const codes = await couponsList?.find(
      promo => value.toLowerCase() === promo.code.toLowerCase()
    )
    codes ? setActiveCoupon(codes) : setActiveCoupon(null)
  }

  // Handle featured tribute logic
  const handleFeaturedTribute = value => {
    const currentPrice = !value
      ? featuredTributePrice + standardTributePrice
      : standardTributePrice

    !value ? setFeaturedPrice(true) : setFeaturedPrice(false)

    if (activeCoupon) {
      if (activeCoupon?.coupon?.amount_off) {
        const amountOff =
          currentPrice - Number(activeCoupon?.coupon?.amount_off / 100)

        setPrice(amountOff)
      } else {
        const amountOff =
          currentPrice -
          currentPrice * (activeCoupon?.coupon?.percent_off / 100)

        setPrice(amountOff)
      }
    } else {
      !value && setPrice(featuredTributePrice + standardTributePrice)
      value && setPrice(standardTributePrice)
    }
  }

  const [fullBirthState, setFullBirthState] = useState("")
  const handleBirthState = (val, values) => {
    const filteredList = allUSCities
      .filter(entry => entry.state_id === val)
      .sort((a, b) => {
        if (a.city < b.city) {
          return -1
        }
        if (a.city > b.city) {
          return 1
        }
        return 0
      })
    setCities(filteredList)
    setBirthCityCategory("Select City")

    // const birthState = allUSState.records.filter(
    //   ({ fields }) => fields.stusab === val
    // )[0].fields.basename
    const birthStateAbbr = allUSState.records.filter(
      ({ fields }) => fields.stusab === val
    )[0].fields.stusab
    setBirthStateCategory(val)
    setFullBirthState(val)
    handleExistedMoms(values, val)
    // fetch(
    //   `https://public.opendatasoft.com/api/records/1.0/search/?dataset=geographic-locator-codes-for-united-states-cities%40public&rows=2574&facet=territory&facet=state_name&facet=city_name_county_name&facet=city_code&refine.state_name=${birthState.toUpperCase()}`
    // )
    //   .then(response => response.json())
    //   .then(result => {
    //     const citiesList =
    //       result.records.length > 0
    //         ? result.records.map(({ fields: { city_name_county_name } }) => {
    //             const temp = city_name_county_name.split(" ")
    //             const capitalized = temp.map(
    //               word =>
    //                 word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    //             )

    //             return capitalized.join(" ")
    //           })
    //         : []
    //     const filteredCities = [
    //       ...new Set(citiesList.filter(name => name !== "Unknown")),
    //     ]
    //     setCities(filteredCities.sort())
    //   })
  }
  const handleCurrentState = (val, values) => {
    const filteredList = allUSCities
      .filter(entry => entry.state_id === val)
      .sort((a, b) => {
        if (a.city < b.city) {
          return -1
        }
        if (a.city > b.city) {
          return 1
        }
        return 0
      })
    setCurrentCities(filteredList)

    // const currentState = allUSState.records.filter(
    //   ({ fields }) => fields.stusab === val
    // )[0].fields.basename
    setCurrentStateCategory(val)
    setCurrentCityCategory("Select City")

    // fetch(
    //   `https://public.opendatasoft.com/api/records/1.0/search/?dataset=geographic-locator-codes-for-united-states-cities%40public&rows=2574&facet=territory&facet=state_name&facet=city_name_county_name&facet=city_code&refine.state_name=${currentState.toUpperCase()}`
    // )
    //   .then(response => response.json())
    //   .then(result => {
    //     const citiesList =
    //       result.records.length > 0
    //         ? result.records.map(({ fields: { city_name_county_name } }) => {
    //             const temp = city_name_county_name.split(" ")
    //             const capitalized = temp.map(
    //               word =>
    //                 word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    //             )

    //             return capitalized.join(" ")
    //           })
    //         : []
    //     const filteredCities = [
    //       ...new Set(citiesList.filter(name => name !== "Unknown")),
    //     ]
    //     setCurrentCities(filteredCities.sort())
    //   })
  }
  const handleBirthCity = (val, values) => {
    setBirthCityCategory(val)
    handleExistedMoms(values, "", val)
  }

  const handleExistedMoms = (values, comingBirthState, comingBirthCity) => {
    const { momFirstName, momLastName, momMaidenName } = values
    const existedMoms = allMoms?.totalMoms?.filter(
      ({
        mom: {
          fields: { firstName, lastName, maidenName, birthState, birthCity },
        },
      }) => {
        return (
          firstName?.toLowerCase().trim() ===
            momFirstName?.toLowerCase().trim() &&
          lastName?.toLowerCase().trim() ===
            momLastName?.toLowerCase().trim() &&
          maidenName?.toLowerCase().trim() ===
            momMaidenName?.toLowerCase().trim() &&
          birthState ===
            (!!comingBirthState ? comingBirthState : fullBirthState) &&
          birthCity ===
            (!!comingBirthCity ? comingBirthCity : birthCityCategory)
        )
      }
    )
    if (existedMoms?.length > 0) {
      dispatch(TributeFormActions.setExistedMom(existedMoms, true))
    } else {
      dispatch(TributeFormActions.momNotExisted())
    }
  }

  return (
    <Formik
      initialValues={{
        momFirstName: "",
        momLastName: "",
        momMaidenName: "",
        name: "",
        email: "",
        description: "",
        featured: false,
        promoCode: "",
      }}
      validationSchema={TributeSubmissionSchema}
      onSubmit={formSubmit}
      handleChange={() => {
        // console.log("acascascas")
      }}
    >
      {({
        errors,
        touched,
        validateForm,
        handleChange,
        values,
        setFieldValue,
      }) => {
        return (
          <Form className={styles.form}>
            <h4 className={styles.formSectionTitle}>
              Your Contact Information
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-7">
              <Input
                label="Your Name"
                required
                type="text"
                placeholder="Your Name..."
                name="name"
                disabled={isLoading}
                error={errors.name && touched.name ? true : false}
              />
              <Input
                label="Your Email Address"
                required
                type="email"
                placeholder="Email..."
                name="email"
                disabled={isLoading}
                error={errors.email && touched.email ? true : false}
              />
            </div>
            <h4 className={`${styles.formSectionTitle} mb-4`}>My Tribute</h4>
            <Textarea
              label="Description"
              required
              placeholder="Description..."
              classes={`${styles.removeMargin}`}
              disabled={isLoading}
              description={tributeDescription}
              setDescription={handleTributeDescription}
            />
            {!state.reTribute && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-7">
                  <Input
                    label="First Name"
                    required
                    type="text"
                    placeholder="First Name*"
                    name="momFirstName"
                    disabled={isLoading}
                    onChange={handleChange}
                    error={
                      errors.momFirstName && touched.momFirstName ? true : false
                    }
                    onKeyUp={() => handleExistedMoms(values)}
                  />
                  <Input
                    label="Last Name"
                    required
                    type="text"
                    placeholder="Last Name*"
                    name="momLastName"
                    onChange={handleChange}
                    disabled={isLoading}
                    error={
                      errors.momLastName && touched.momLastName ? true : false
                    }
                    onKeyUp={() => handleExistedMoms(values)}
                  />
                  <Input
                    label="Maiden Name"
                    type="text"
                    placeholder="Maiden Name"
                    name="momMaidenName"
                    onChange={handleChange}
                    disabled={isLoading}
                    error={
                      errors.momMaidenName && touched.momMaidenName
                        ? true
                        : false
                    }
                    onKeyUp={() => handleExistedMoms(values)}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-7">
                  <div className="relative">
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      disabled={isLoading}
                      showMonthDropdown
                      showYearDropdown
                      maxDate={new Date()}
                      dropdownMode="select"
                      customInput={
                        <CustomDatePicker
                          placeholderText="mm/dd/yyyy"
                          label="Birth Date"
                          error={error && startDate === null}
                          disabled={isLoading}
                          required
                        />
                      }
                      excludeDates={[new Date()]}
                    />
                  </div>
                  <Dropdown
                    label="Birth State"
                    defaultValue={birthStateCategory}
                    required
                    options={states}
                    changeSelected={val => handleBirthState(val, values)}
                    error={error && birthStateCategory === "Select State"}
                    disabled={isLoading}
                  />
                  <Dropdown
                    type="cities"
                    label="Birth City"
                    defaultValue={birthCityCategory}
                    required
                    options={cities}
                    changeSelected={val => handleBirthCity(val, values)}
                    disabled={isLoading || cities.length < 1 ? true : false}
                    error={
                      error &&
                      cities.length >= 1 &&
                      birthCityCategory === "Select City"
                    }
                    search={true}
                  />
                </div>
              </>
            )}
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-7">
              <Dropdown
                label="Vital Status"
                defaultValue={vitalStatus}
                required
                options={["Alive", "Deceased"]}
                disabled={isLoading}
                changeSelected={val => setVitalStatus(val)}
                error={error && vitalStatus === "Select Status"}
              />
              <Dropdown
                label={
                  vitalStatus === "Deceased"
                    ? "Last State of Residence"
                    : "Current State"
                }
                defaultValue={currentStateCategory}
                required
                options={states}
                disabled={isLoading}
                changeSelected={handleCurrentState}
                error={error && currentStateCategory === "Select State"}
              />
              <Dropdown
                type="cities"
                label={
                  vitalStatus === "Deceased"
                    ? "Last City of Residence"
                    : "Current City"
                }
                defaultValue={currentCityCategory}
                required
                options={currentCities}
                changeSelected={val => setCurrentCityCategory(val)}
                disabled={isLoading || currentCities.length < 1 ? true : false}
                error={
                  error &&
                  currentCities.length >= 1 &&
                  currentCityCategory === "Select City"
                }
                search={true}
              />
            </div>
            {state.existedMoms &&
              state.existedMoms.length > 0 &&
              !state.reTribute && (
                <SimilarTributesSection
                  data={state.existedMoms}
                  toggle={toggleTribute}
                  setReTributeData={setReTributeData}
                />
              )}
            {/* Image upload */}
            <FileUpload
              label="Upload Photos"
              type="image"
              images={images}
              upload={setImages}
              disabled={isLoading}
              required
            />
            {error && images.length < 1 && (
              <p className={styles.errorNote}>
                <span>Note:</span> *At least 1 or more images are required
              </p>
            )}

            {/* Video Upload */}
            <FileUpload
              type="video"
              label="Upload Video"
              required
              videoUrls={videoUrls}
              setVideoUrls={setVideoUrls}
              disabled={isLoading}
            />
            <div>
              <CheckBox
                disabled={isLoading}
                label="Feature my Mom for <span>$9.99"
                onChange={() => {
                  handleFeaturedTribute(values.featured)
                  setFieldValue("featured", !values.featured)
                }}
              />
              <div className={styles.agreementTerms}>
                <RichTextRenderer content={featureDetails.raw} />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3">
              {values.featured && (
                <div className="relative">
                  <DatePicker
                    selected={featuredDate}
                    onChange={date => setFeaturedDate(date)}
                    minDate={new Date()}
                    disabled={isLoading}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={
                      <CustomDatePicker
                        placeholderText="mm/dd/yyyy"
                        label="Date to feature this tribute"
                        error={error && featuredDate === null}
                        disabled={isLoading}
                        required
                      />
                    }
                    excludeDates={
                      allMoms?.featuredDates?.length > 0
                        ? [
                            new Date(),
                            ...allMoms.featuredDates.map(
                              featuredDate => new Date(featuredDate)
                            ),
                          ]
                        : [new Date()]
                    }
                  />
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-7">
              <Input
                label="Promo Code"
                type="text"
                placeholder=""
                classes="col-span-2"
                disabled={isLoading}
                name="promoCode"
                onKeyUp={() => handlePromotionalCode(values.promoCode)}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3">
              <div className={`${styles.paymentSection} col-span-2`}>
                <div
                  className={`${styles.paymentEntry} ${styles.featuredPayment}`}
                >
                  <h4>Tribute - Introductory Price!</h4>
                  <span>${standardTributePrice}</span>
                </div>
                {values.featured && (
                  <div className={styles.paymentEntry}>
                    <h4>Feature</h4>
                    <span>${featuredTributePrice}</span>
                  </div>
                )}
                {activeCoupon && (
                  <>
                    <h5 className="font-semibold">Coupon Applied:</h5>
                    <div className={styles.paymentEntry}>
                      <h5>{activeCoupon.code.toUpperCase()}</h5>
                      <span>
                        -
                        {activeCoupon.coupon.amount_off
                          ? `$${activeCoupon.coupon.amount_off / 100}`
                          : `${activeCoupon.coupon.percent_off}%`}
                      </span>
                    </div>
                  </>
                )}
                <hr />
                <div className={styles.finalPayment}>
                  <h4>Total</h4>
                  <span>${price <= 0 ? 0 : price}</span>
                </div>

                <button
                  type="submit"
                  className="w-full btn twine-gradient"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <img src={loader} className="mx-auto w-6" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

const TributeForm = ({
  data: { formTitle, formDetails, image, featureMomDetails },
  reTribute,
  toggleTribute,
  reTributeData,
  setReTributeData,
}) => {
  const [progress, setProgress] = useState(0)
  return (
    <div className={styles.tributeForm}>
      {progress !== 100 && <ProgressBar completed={progress} />}
      <div className="max-w-3xl mx-auto px-4 xl:px-0">
        <AboutForm
          title={formTitle}
          details={formDetails}
          image={image}
          reTribute={reTribute}
          data={reTributeData}
        />
        <SubmissionForm
          momId={reTributeData.id}
          featureDetails={featureMomDetails}
          reTribute={reTribute}
          setReTributeData={setReTributeData}
          toggleTribute={toggleTribute}
          setProgress={setProgress}
        />
      </div>
    </div>
  )
}

export default TributeForm
